<template>
  <div class="toolbar">
    <v-toolbar app fixed transparent>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <span class="hidden-sm-and-down">
          <label v-if="currentUser.tipo === 'A'">ADMINISTRADOR</label>
          <label v-if="currentUser.tipo !== 'A'">{{currentUser.unidade}}</label>
        </span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom v-if="currentUser.tipo !== 'V'">
        <template v-slot:activator="{ on }">
          <v-btn flat @click="goCadastros()" v-on="on">
            <v-icon large dark>manage_accounts</v-icon>
          </v-btn>
        </template>
        <span>Cadastros</span>
      </v-tooltip>

      <v-toolbar-items>
        <div class="text-xs-center hidden-sm-and-down">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn fab icon slot="activator" v-on="on">
                <v-avatar size="40">
                  <img
                    @click="openProfile()"
                    v-if="!currentUser.photo"
                    :src="`https://ui-avatars.com/api/?name=${currentUser.nome}&size=30`"
                  />
                  <!-- <img @click="openProfile()" v-if="currentUser.photo" :src="currentUser.photo" /> -->
                </v-avatar>
              </v-btn>
            </template>
            <span>Meu perfil</span>
          </v-tooltip>
          <v-menu offset-y left>
            <v-btn flat slot="activator">
              {{currentUser.nome}}
              <v-icon right dark>keyboard_arrow_down</v-icon>
            </v-btn>
            <v-list>
              <v-list-tile @click="alterarSenha()">
                <v-list-tile-title>Alterar senha</v-list-tile-title>
              </v-list-tile>
              <v-list-tile @click="exit()">
                <v-list-tile-title>Sair</v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
const unidadeModule = () => import('./../services/unidade')

export default {
  name: 'Toolbar',
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  },
  methods: {
    changeDrawer () {
      this.$eventHub.$emit('changeDrawer')
    },
    exit () {
      this.$router.push('/')
    },
    goCadastros () {
      // this.$router.push('/canal')
      this.goCadByPermissions()
    },
    alterarSenha () {
      this.$router.push('/usuario/alterar/senha')
    },
    async goCadByPermissions () {
      let unidadeService = await unidadeModule()
      if (this.currentUser.tipo === 'A') {
        this.$router.push('/canal')
      } else if (this.currentUser.tipo === 'C') {
        // VAI PARA CAD LOJA
        let resp = await unidadeService.getByUser(this.$axios)
        let canal = resp.data
        let encode = btoa(canal.codigo)
        this.$router.push(`/loja/${encode}`)
      } else if (this.currentUser.tipo === 'L') {
        // VAI PARA CADASTRO DE USUARIOS
        let resp = await unidadeService.getByUser(this.$axios)
        let loja = resp.data
        let encode = btoa(loja.codigo)
        this.$router.push(`/usuario/${encode}`)
      }
    }
  },
}
</script>